






































import Page from '@/components/swine/layout/Page.vue';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import ClientFormTemplate from './ClientFormTemplate.vue';
import { ClientStores } from '@/store/swine/client/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Farm } from '@/models/swine/farm/farm.model';
import { DEFAULT_SELECT_OPTION, Role } from '@/utils/constants';
import {
  DropdownOptionRequest,
  DropdownOptionResponse,
} from '@/models/swine/client/client.model';
import { CountryStores } from '@/store/swine/country';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { generateUniqueId } from '@/utils/lodash';
import { UserSessionStore } from '@/store/swine/user-session';
import { ProfileStores } from '@/store/swine/profile';

@Component({
  components: {
    Page,
    InnerPageTemplate,
    ClientFormTemplate,
  },
})
export default class CreateClient extends Vue {
  store = ClientStores.create;
  countryStore = CountryStores.list;
  isUserRegional = false;
  isCountryDropdownLock = false;
  fixedCountrySelection: number | null = null;
  currentRole = '';
  currentCountry = '';
  userSessionDetails = UserSessionStore.detail;
  userProfileStore = ProfileStores.profile;
  clientDetailStore = ClientStores.detail;

  countries: DropdownOption[] = [];

  regionOptionResponse: DropdownOptionResponse = {
    code: 0,
    list: DEFAULT_SELECT_OPTION,
  };

  stateOptionResponse: DropdownOptionResponse = {
    code: 0,
    list: DEFAULT_SELECT_OPTION,
  };

  cityOptionResponse: DropdownOptionResponse = {
    code: 0,
    list: DEFAULT_SELECT_OPTION,
  };

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      this.currentRole = this.userProfileStore.userProfile.role;
      this.currentCountry = this.userProfileStore.userProfile.country
        ? this.userProfileStore.userProfile.country.code
        : '';
      this.countryStore.getAllCountries();
      this.clientDetailStore.setEditPage(false);
    }
  }

  @Watch(`countryStore.response`)
  updateCountryList() {
    if (this.countryStore.response) {
      this.countries = [];
      this.countries.push(...DEFAULT_SELECT_OPTION);
      if (this.countries) {
        this.countryStore.response.map((item) => {
          if (
            (this.currentRole === Role.Admin ||
              this.currentRole === Role.Auditor) &&
            this.currentCountry === item.code
          ) {
            this.isCountryDropdownLock = true;
            this.fixedCountrySelection = item.id;
          }

          this.countries.push({
            text: item.name,
            value: String(item.id),
            uniqueKey: generateUniqueId(`${item.name}_`),
          });
        });
      }
    }
  }

  @Watch('store.regionsList')
  updateRegionOptions() {
    if (this.store.regionsList) {
      this.regionOptionResponse = this.store.regionsList;
    }
  }

  @Watch('store.statesList')
  updateStateOptions() {
    if (this.store.statesList) {
      this.stateOptionResponse = this.store.statesList;
    }
  }

  @Watch('store.citiesList')
  updateCityOptions() {
    if (this.store.citiesList) {
      this.cityOptionResponse = this.store.citiesList;
    }
  }

  updateName(params: string) {
    this.store.updateName(params);
  }

  updateStreetAddress(params: string) {
    this.store.updateStreetAddress(params);
  }

  updateCountry(params: DropdownOptionRequest) {
    this.store.updateCountry(params.value);
    this.store.updateRegion('');
    this.store.updateState('');
    this.store.updateCity('');
    if (params.value !== '') {
      this.store.getRegions(params);
    } else {
      this.store.updateClientCode('');
    }
  }

  updateRegion(params: DropdownOptionRequest) {
    this.store.updateRegion(params.value);
    this.store.updateState('');
    this.store.updateCity('');

    if (params.value !== '') {
      this.store.getStates(params);
    } else {
      this.store.updateClientCode('');
    }
  }

  updateState(params: DropdownOptionRequest) {
    this.store.updateState(params.value);
    this.store.updateCity('');

    if (params.value !== '') {
      const species = this.$router.currentRoute.fullPath.split('/')[1];

      this.store.getCities(params);
      this.store.setSpecies(species);
      this.store.getClientCode();
    } else {
      this.store.updateClientCode('');
    }
  }

  updateCity(params: string) {
    this.store.updateCity(params);
  }

  updatePostcode(params: string) {
    this.store.updatePostcode(params);
  }

  updatePhoneNumber(params: string) {
    this.store.updatePhone(params);
  }

  updateFarm(params: Farm[]) {
    this.store.updateFarm(params);
  }

  requestRegion(params: DropdownOptionRequest) {
    this.store.getRegions(params);
  }

  requestState(params: DropdownOptionRequest) {
    this.store.getStates(params);
  }

  requestCity(params: DropdownOptionRequest) {
    this.store.getCities(params);
  }

  async createClient() {
    const species = this.$router.currentRoute.fullPath.split('/')[1];
    this.store.setSpecies(species);
    this.store.createClient();
  }

  @Watch('store.response')
  checkPostResponse() {
    if (this.store.response && this.store.response.status === 201) {
      this.store.resetStore();

      this.$router.push({
        name: 'ClientManagement',
      });
    }
  }

  get buttonDisabled() {
    // We need to check for the client details that are required (easy)
    // But we also need to check all nested objects
    if (this.store.clientDetails) {
      const baseFormIsValid =
        this.store.clientDetails.name!.length > 0 &&
        this.store.clientDetails.countryId!.length > 0 &&
        this.store.clientDetails.regionId!.length > 0 &&
        this.store.clientDetails.stateId!.length > 0 &&
        this.store.clientDetails.clientCode!.length > 0;

      const subFormIsValid =
        this.store.clientDetails.farms!.length > 0
          ? !this.store.clientDetails.farms!.some(
              (farm) => farm.name?.length === 0
            )
          : true;
      const wholeFormIsValid = baseFormIsValid && subFormIsValid;
      return !wholeFormIsValid;
    }
    return true;
  }
}
